import { Center, Image, Link, Skeleton } from '@chakra-ui/react';
import React from 'react';

type Props = {
  icon?: string;
  text: string;
  url: string;
  isLoading?: boolean;
};

const GelioFooter = ({ icon, text, url, isLoading }: Props) => {
  if (isLoading) {
    return <Skeleton my="3px">{ text }</Skeleton>;
  }

  return (
    <Link
      href={ url }
      display="flex"
      alignItems="center"
      h="30px"
      variant="secondary"
      target="_blank"
      fontSize="xs"
    >
      { icon && (
        <Center minW={ 6 } mr={ 2 }>
          <Image
            src={ `/assets/images/footer/${ icon }.svg` }
            alt={ icon }
            width={ 31 }
            height={ 31 }
          />
        </Center>
      ) }
      { /* <IconSvg boxSize={ iconSize || 5 } name={ icon }/> */ }
      { /* { text } */ }
    </Link>
  );
};

export default GelioFooter;
