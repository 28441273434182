import type { GridProps } from '@chakra-ui/react';
import {
  Box,
  Flex,
  Grid,
  Image,
  Link,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import type { CustomLinksGroup } from 'types/footerLinks';

import config from 'configs/app';
import type { ResourceError } from 'lib/api/resources';
import useFetch from 'lib/hooks/useFetch';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import FooterLinkItem from './FooterLinkItem';
import GelioFooter from './GelioFooter';
// import GelioFooter from './GelioFooter';
// import IntTxsIndexingStatus from './IntTxsIndexingStatus';

const MAX_LINKS_COLUMNS = 4;
// const FRONT_VERSION_URL = `https://github.com/blockscout/frontend/tree/${ config.UI.footer.frontendVersion }`;
// const FRONT_COMMIT_URL = `https://github.com/blockscout/frontend/commit/${ config.UI.footer.frontendCommit }`;

const Footer = () => {
  // const { data: backendVersionData } = useApiQuery('config_backend_version', {
  //   queryOptions: {
  //     staleTime: Infinity,
  //   },
  // });
  // const apiVersionUrl = getApiVersionUrl(backendVersionData?.backend_version);
  // const issueUrl = useIssueUrl(backendVersionData?.backend_version);3
  const BLOCKSCOUT_LINKS = [
    // {
    //   icon: 'edit' as const,
    //   iconSize: '16px',
    //   text: 'Submit an issue',
    //   url: issueUrl,
    // },
    // {
    //   icon: 'social/canny' as const,
    //   iconSize: '20px',
    //   text: 'Feature request',
    //   url: 'https://blockscout.canny.io/feature-requests',
    // },
    // {
    //   icon: 'social/git' as const,
    //   iconSize: '18px',
    //   text: 'Contribute',
    //   url: 'https://github.com/blockscout/blockscout',
    // },

    // {
    //   icon: 'discord' as const,
    //   // iconSize: '24px',
    //   text: 'Discord',
    //   url: '',
    // },
    {
      // icon: 'social/tweet' as const,
      icon: 'twitter' as const,
      iconSize: '18px',
      text: 'Twitter',
      url: 'https://x.com/TONSquare_io',
    },
    {
      // icon: 'social/tweet' as const,
      icon: 'telegram' as const,
      // iconSize: '18px',
      text: 'Telegram',
      url: 'https://t.me/TONSquare_io',
    },
    {
      // icon: 'social/tweet' as const,
      icon: 'gitbook' as const,
      // iconSize: '18px',
      text: 'Docs',
      url: 'https://docs.tonsquare.io',
    },
    // {
    //   icon: 'medium' as const,
    //   // iconSize: '24px',
    //   text: 'Medium',
    //   url: 'https://tonsquare.medium.com/',
    // },
    // {
    //   icon: 'discussions' as const,
    //   iconSize: '20px',
    //   text: 'Discussions',
    //   url: 'https://github.com/orgs/blockscout/discussions',
    // },
    // {
    //   icon: 'donate' as const,
    //   iconSize: '20px',
    //   text: 'Donate',
    //   url: 'https://github.com/sponsors/blockscout',
    // },
  ];

  // const frontendLink = (() => {
  //   if (config.UI.footer.frontendVersion) {
  //     return (
  //       <Link href={ FRONT_VERSION_URL } target="_blank">
  //         { config.UI.footer.frontendVersion }
  //       </Link>
  //     );
  //   }

  //   if (config.UI.footer.frontendCommit) {
  //     return (
  //       <Link href={ FRONT_COMMIT_URL } target="_blank">
  //         { config.UI.footer.frontendCommit }
  //       </Link>
  //     );
  //   }

  //   return null;
  // })();

  const fetch = useFetch();

  const { isPlaceholderData, data: linksData } = useQuery<
  unknown,
  ResourceError<unknown>,
  Array<CustomLinksGroup>
  >({
    queryKey: [ 'footer-links' ],
    queryFn: async() =>
      fetch(config.UI.footer.links || '', undefined, {
        resource: 'footer-links',
      }),
    enabled: Boolean(config.UI.footer.links),
    staleTime: Infinity,
    placeholderData: [],
  });

  const colNum = isPlaceholderData ?
    1 :
    Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;

  const renderNetworkInfo = React.useCallback(
    (gridArea?: GridProps['gridArea']) => {
      return (
        <Flex
          gridArea={ gridArea }
          flexWrap="wrap"
          columnGap={ 8 }
          rowGap={ 6 }
          mb={{ base: 2, lg: 2 }}
          _empty={{ display: 'none' }}
        >
          { /* { !config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus/> } */ }
          <NetworkAddToWallet/>
        </Flex>
      );
    },
    [],
  );

  const renderProjectInfo = React.useCallback(
    (gridArea?: GridProps['gridArea']) => {
      return (
        <Box gridArea={ gridArea }>
          <Box display="flex" alignItems="center" gap={ 1 } fontSize={ 18 }>
            <Text fontSize="md">
              <Image
                src="/assets/images/logo.svg"
                alt=" logo"
                display="block"
              />
              <div>{ renderNetworkInfo({ lg: 'network' }) }</div>
              Powered by <Link href="https://tonsquare.io">TONSquare</Link>
            </Text>
          </Box>

          <Text fontSize="xs">
            TONSquare is the most practical TON Layer-2 Network.
          </Text>
        </Box>
      );
    },
    [ renderNetworkInfo ],
  );

  const containerProps: GridProps = {
    as: 'footer',
    px: { base: 4, lg: 12 },
    py: { base: 4, lg: 9 },
    borderTop: '1px solid',
    borderColor: 'divider',
    gridTemplateColumns: { base: '1fr', lg: 'minmax(auto, 470px) 1fr' },
    columnGap: { lg: '32px', xl: '100px' },
  };

  if (config.UI.footer.links) {
    return (
      <Grid
        { ...containerProps }
        gridTemplateAreas={{
          lg: `
          "network links-top"
          "info links-bottom"
        `,
        }}
      >
        <div>
          { renderNetworkInfo({ lg: 'network' }) }
          { renderProjectInfo({ lg: 'info' }) }
        </div>

        <Grid
          gap={{
            base: 6,
            lg: colNum === MAX_LINKS_COLUMNS + 1 ? 2 : 8,
            xl: 12,
          }}
          gridTemplateColumns={{
            base: 'repeat(auto-fill, 160px)',
            lg: `repeat(${ colNum }, 135px)`,
            xl: `repeat(${ colNum }, 160px)`,
          }}
          justifyContent={{ lg: 'flex-end' }}
          mt={{ base: 8, lg: 0 }}
        >
          { [
            { title: 'Blockscout', links: BLOCKSCOUT_LINKS },
            ...(linksData || []),
          ]
            .slice(0, colNum)
            .map((linkGroup) => (
              <Box key={ linkGroup.title }>
                <Skeleton
                  fontWeight={ 500 }
                  mb={ 3 }
                  display="inline-block"
                  isLoaded={ !isPlaceholderData }
                >
                  { linkGroup.title }
                </Skeleton>
                <VStack spacing={ 1 } alignItems="start">
                  { linkGroup.links.map((link) => (
                    <FooterLinkItem
                      { ...link }
                      key={ link.text }
                      isLoading={ isPlaceholderData }
                    />
                  )) }
                </VStack>
              </Box>
            )) }
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      { ...containerProps }
      gridTemplateAreas={{
        lg: `
          "network links-top"
          "info links-bottom"
        `,
      }}
    >
      <Box>{ renderProjectInfo({ lg: 'info' }) }</Box>

      <Flex
        alignItems="center"
        justifyContent={{
          base: 'center',
          lg: 'flex-end',
        }}
      >
        { BLOCKSCOUT_LINKS.map((link, idx) => (
          <Box key={ idx } my={{ base: 4, lg: 0 }}>
            <GelioFooter { ...link } key={ link.text }/>
          </Box>
        )) }
      </Flex>
    </Grid>
  );
};

export default React.memo(Footer);
