import { useColorModeValue } from '@chakra-ui/react';

export default function useColors() {
  return {
    text: {
      'default': useColorModeValue('gray.600', 'gray.400'),
      active: useColorModeValue('blue.700', 'gray.50'),
      // active: useColorModeValue('gray.600', 'gray.600'),
      hover: useColorModeValue('blue.700', 'blue.50'),
      //  'link_hovered',
    },
    bg: {
      'default': 'transparent',
      active: useColorModeValue('blue.100', 'blue.800'),
    },
    border: {
      'default': 'divider',
      active: useColorModeValue('blue.50', 'blue.800'),
    },
  };
}
