import { Box, Image, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import { route } from 'nextjs-routes';

import config from 'configs/app';

interface Props {
  isCollapsed?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
}

// const LogoFallback = ({
//   isCollapsed,
//   isSmall,
// }: {
//   isCollapsed?: boolean;
//   isSmall?: boolean;
// }) => {
//   const field = isSmall ? 'icon' : 'logo';
//   const logoColor = useColorModeValue('blue.600', 'white');

//   const display = isSmall ?
//     {
//       base: 'none',
//       lg: isCollapsed === false ? 'none' : 'block',
//       xl: isCollapsed ? 'block' : 'none',
//     } :
//     {
//       base: 'block',
//       lg: isCollapsed === false ? 'block' : 'none',
//       xl: isCollapsed ? 'none' : 'block',
//     };

//   if (config.UI.sidebar[field].default) {
//     return <Skeleton w="100%" borderRadius="sm" display={ display }/>;
//   }

//   return (
//     <IconSvg
//       name={ isSmall ? 'networks/icon-placeholder' : 'networks/logo-placeholder' }
//       width="auto"
//       height="100%"
//       color={ logoColor }
//       display={ display }
//     />
//   );
// };

const NetworkLogo = ({ isCollapsed, onClick }: Props) => {
  // const logoSrc = useColorModeValue(
  //   config.UI.sidebar.logo.default,
  //   config.UI.sidebar.logo.dark || config.UI.sidebar.logo.default,
  // );

  const darkModeFilter = { filter: 'brightness(0) invert(1)' };
  const logoStyle = useColorModeValue(
    {},
    !config.UI.sidebar.logo.dark ? darkModeFilter : {},
  );
  const iconStyle = useColorModeValue(
    {},
    !config.UI.sidebar.icon.dark ? darkModeFilter : {},
  );

  return (
    <Box
      as="a"
      href={ route({ pathname: '/' }) }
      width={{
        base: '120px',
        lg: isCollapsed === false ? '120px' : '120px',
        xl: isCollapsed ? '30px' : '120px',
      }}
      height={{
        base: '50px',
        lg: isCollapsed === false ? '50px' : '30px',
        xl: isCollapsed ? '30px' : '24px',
      }}
      display="inline-flex"
      overflow="hidden"
      onClick={ onClick }
      flexShrink={ 0 }
      aria-label="Link to main page"
    >
      { /* big logo */ }
      <Image
        w="auto"
        h="100%"
        // src={ logoSrc }
        src="/assets/images/desktop-logo.svg"
        alt={ `${ config.chain.name } network logo` }
        display={{
          base: 'block',
          lg: isCollapsed === false ? 'block' : 'none',
          xl: isCollapsed ? 'none' : 'block',
        }}
        style={ logoStyle }
      />

      { /* small logo */ }
      <Image
        w="auto"
        h="100%"
        src="/assets/images/logo.svg"
        alt={ `${ config.chain.name } network logo` }
        display={{
          base: 'none',
          lg: isCollapsed === false ? 'none' : 'block',
          xl: isCollapsed ? 'block' : 'none',
        }}
        style={ iconStyle }
      />
    </Box>
  );
};

export default React.memo(NetworkLogo);
